export const menuItems = [
    {
        id: 10,
        label: 'Employees',
        link: "/employees",
        icon: "bxs-user-detail",
        appDisplay: true,
        acl: ['admin', 'hrm']
    },
    {
        id: 14,
        label: 'Interviews',
        link: '/interviews',
        icon: "bxs-user-plus",
        appDisplay: true,
        acl: ['admin', 'hrm', 'recruiter']
    },
    {
        id: 21,
        label: 'Feedback',
        link: "/feedback",
        icon: "bxs-conversation",
        appDisplay: true,
        acl: ['admin', 'hrm']
    },
    {
        id: 13,
        label: 'Settings',
        link: "/settings/0",
        icon: "bxs-cog",
        appDisplay: true,
        acl: ['admin', 'hrm']
    },
    {
        id: 9,
        label: 'Activity Log',
        link: "/activity-log",
        icon: "bxs-bell",
        appDisplay: true,
        acl: ['admin', 'hrm']
    },
];
